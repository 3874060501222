<template>
  <v-container fluid>
    <div class="main">
      <v-skeleton-loader
        type="table"
        v-if="loading"
        class="mb-3"
      ></v-skeleton-loader>
      <v-row v-else>
        <v-col cols="12">
          <v-sheet :rounded="'lg'">
            <v-card-title>
              <v-text-field
                v-model="filter.keyword"
                rounded
                filled
                dense
                append-icon="mdi-magnify"
                single-line
                hide-details
                clearable
                placeholder="Rechercher..."
              />
            </v-card-title>
            <v-card-text>
              <div v-if="accounts.length > 0">
                <v-simple-table fixed-header height="600px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th class="font-weight-bold text-no-wrap">
                          Account ID
                        </th>
                        <th class="font-weight-bold text-no-wrap">User ID</th>
                        <th class="font-weight-bold text-no-wrap">Compte</th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Montant reçu
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Balance
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Balance a credit
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Somme des balances
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Flexy
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Vouchers
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Cartes
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Sent Refill
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Consommation
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Compaigns
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Commandes
                        </th>

                        <th class="font-weight-bold text-center text-no-wrap">
                          Commissions
                        </th>
                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Frais Services
                        </th>

                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Promotions Services
                        </th>

                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Frais Commandes
                        </th>

                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Promotions Commandes
                        </th>

                        <th class="font-weight-bold text-center text-no-wrap">
                          Total Code Promo Commandes
                        </th>

                        <th class="font-weight-bold text-center text-no-wrap">
                          Erreur (Montant de Plus)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in accounts" :key="item.id">
                        <td
                          class="font-weight-bold text-no-wrap"
                          style="width: 50px"
                        >
                          <v-img :src="getImageLink(item.user.image)" width="50" />
                        </td>
                        <td class="font-weight-bold text-no-wrap">
                          {{ item.id }}
                        </td>
                        <td class="font-weight-bold text-no-wrap">
                          {{ item.user_id }}
                        </td>
                        <td class="font-weight-bold text-no-wrap">
                          {{ item.user ? item.user.name : "Unnamed User" }}
                        </td>
                        <td
                          class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                        >
                          {{
                            CurrencyFormatting(item.total_received_refills)
                          }}
                          DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.balance) }} DZD
                        </td>
                        <td
                          class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.credit_balance) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 primary lighten-5 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.all_balance) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_flexy) }} DZD
                        </td>
                        <td
                          class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                        >
                          {{
                            CurrencyFormatting(item.total_consumed_vouchers)
                          }}
                          DZD
                        </td>
                        <td
                          class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                        >
                          {{
                            CurrencyFormatting(item.total_consumed_cards)
                          }}
                          DZD
                        </td>
                        <td
                          class="font-weight-bold pink--text text-center pr-4 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_sent_refills) }} DZD
                        </td>
                        <td
                          class="font-weight-bold pink--text text-center pr-4 primary lighten-5 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_consumed) }} DZD
                        </td>
                        <td
                          class="font-weight-bold pink--text text-center pr-4 success lighten-5 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_compaign) }} DZD
                        </td>
                        
                        <td
                          class="font-weight-bold pink--text text-center pr-4 orange lighten-5 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_orders) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 blue lighten-5 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_commissions) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 success lighten-5 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.total_transaction_fees) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 success lighten-5 text-no-wrap">
                          {{ CurrencyFormatting(item.total_transaction_promotions) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 success lighten-5 text-no-wrap">
                          {{ CurrencyFormatting(item.total_voucher_order_fees) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 success lighten-5 text-no-wrap">
                          {{ CurrencyFormatting(item.total_voucher_order_promotions) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 success lighten-5 text-no-wrap">
                          {{ CurrencyFormatting(item.total_voucher_order_promo_codes) }} DZD
                        </td>

                        <td
                          class="font-weight-bold pink--text text-center pr-4 error lighten-5 text-no-wrap"
                        >
                          {{ CurrencyFormatting(item.error) }} DZD
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div v-else>
                <div class="text-center">
                  <v-avatar tile size="200">
                    <v-img :src="require('@/assets/database.svg')"></v-img>
                  </v-avatar>
                  <h3 class="text--primary d-block mb-2">OOPS !</h3>
                  <p>Aucun enregistrement correspondant trouvé.</p>
                </div>
              </div>
            </v-card-text>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "@/http-common";

export default {
  data() {
    return {
      loading: true,
      accounts: [],
      allAccounts: [],

      filter: {
        keyword: "",
      },
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      HTTP.get("/anomaly/users")
        .then((res) => {
          this.accounts = res.data.data;
          this.allAccounts = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    filterAccounts() {
      var filteredData = this.allAccounts.filter((obj) => {
        console.log(obj);
        return obj.user
          ? obj.user.name.toLowerCase().includes(this.keyword.toLowerCase())
          : false;
      });
      this.accounts = filteredData;
    },
  },
  computed: {
    keyword() {
      return this.filter.keyword;
    },
  },
  watch: {
    keyword() {
      this.filterAccounts();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
